export const track_home_screen = {
  eventName: "page_view",
  eventParameters: {
    page_title: "home",
  },
};

export const track_releases_screen = {
  eventName: "page_view",
  eventParameters: {
    page_title: "releases",
  },
};

export const track_release_screen = (release_id) => {
  return {
    eventName: "page_view",
    eventParameters: {
      page_title: "release",
      release: release_id,
    },
  };
};

export const track_outbound_click = (screen, network, url, release_id) => {
  return {
    eventName: "outbound_click",
    eventParameters: {
      network: network,
      outbound_url: url,
      page_title: screen,
      release: release_id,
    },
  };
};

export const track_home_release_click = (release_id, element) => {
  return {
    eventName: "release_card_click",
    eventParameters: {
      release: release_id,
      page_title: "home",
      element: element,
    },
  };
};

export const track_home_hero_click = (release_id, element) => {
  return {
    eventName: "hero_title_click",
    eventParameters: {
      release: release_id,
      page_title: "home",
      element: element,
    },
  };
};
