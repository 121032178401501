import React from "react";
import Footer from "../components/footer";

class CampaignContainer extends React.Component {
  render() {
      let canvasImage;
      if ("canvas" in this.props.canvasImage) {
        canvasImage = require("../images/campaigns/" + this.props.canvasImage.canvas).default
      } else {
        canvasImage = require("../images/artwork/" + this.props.canvasImage.artwork).default
      }
      
    return (
      <div className="canvas" style={{backgroundImage: `url(${canvasImage})`}}>
        <div className={this.props.blurBackground ? "overlay" : null}>
            <div className="App">{this.props.children}</div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default CampaignContainer;
