import React from "react";
import store from "../analytics/store";
import { Redirect } from "react-router-dom";
import AppContainer from "../components/container";
import Header from "../components/header";
import { useParams } from "react-router-dom";
import { releases } from "../content/latestReleases";
import YouTubeVideo from "../components/youtubeEmbed";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpotify,
  faItunes,
  faInstagram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import {
  track_release_screen,
  track_outbound_click,
} from "../analytics/events";
import "../css/app.css";
import "../css/latestReleases.css";

function ReleaseScreen() {
  const { id } = useParams();
  let release;
  if (id in releases) {
    release = releases[id];
    release.id = id;
  } else {
    return <Redirect to={"/"} />;
  }
  let release_screen = track_release_screen(id);
  store.getState().track(release_screen, {super_fan: true, is_human: "yes"});
  return (
    <AppContainer>
      <Header />
      <div className="latestReleases">
        <div className="releaseCard">
          <div className="releaseCardInfo">
            <img
              className="releaseArtwork"
              src={require("../images/artwork/" + release.image).default}
              alt={`${release.artist} - ${release.title}`}
            />
            <h1>{release.title}</h1>
            <h3>{release.artist}</h3>
            <h4 className="font-thin">{release.date}</h4>
            <div className="releaseCardSocialIcons">
              <ul className="socialIconsList">
                {release.spotify ? (
                  <li>
                    <a
                      href={release.spotify}
                      onClick={() => {
                        store
                          .getState()
                          .track(
                            track_outbound_click(
                              `release`,
                              "spotify",
                              release.spotify,
                              release.id
                            )
                          );
                      }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon
                        icon={faSpotify}
                        size="2x"
                        className="fa-custom-hover"
                      />
                    </a>
                  </li>
                ) : null}
                {release.apple ? (
                  <li>
                    <a
                      href={release.apple}
                      onClick={() => {
                        store
                          .getState()
                          .track(
                            track_outbound_click(
                              `release`,
                              "apple",
                              release.apple,
                              release.id
                            )
                          );
                      }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon
                        icon={faItunes}
                        size="2x"
                        className="fa-custom-hover"
                      />
                    </a>
                  </li>
                ) : null}
                {release.instagram ? (
                  <li>
                    <a
                      href={`https://instagram.com/${release.instagram}`}
                      onClick={() => {
                        store
                          .getState()
                          .track(
                            track_outbound_click(
                              `release`,
                              "instagram",
                              release.instagram,
                              release.id
                            )
                          );
                      }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon
                        icon={faInstagram}
                        size="2x"
                        className="fa-custom-hover"
                      />
                    </a>
                  </li>
                ) : null}
                {release.twitter ? (
                  <li>
                    <a
                      href={`https://twitter.com/${release.twitter}`}
                      onClick={() => {
                        store
                          .getState()
                          .track(
                            track_outbound_click(
                              `release`,
                              "twitter",
                              release.twitter,
                              release.id
                            )
                          );
                      }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon
                        icon={faTwitter}
                        size="2x"
                        className="fa-custom-hover"
                      />
                    </a>
                  </li>
                ) : null}
              </ul>
              {release.musixmatch ? (
                <a
                  href={release.musixmatch}
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => {
                    store
                      .getState()
                      .track(
                        track_outbound_click(
                          `release`,
                          "musixmatch",
                          release.musixmatch,
                          release.id
                        )
                      );
                  }}
                >
                  Lyrics on MusixMatch.com
                </a>
              ) : null}
            </div>
          </div>
          <div className="releaseCardVideo">
            <YouTubeVideo
              artist={release.artist}
              title={release.title}
              video={release.youtube}
            />
          </div>
        </div>
      </div>
    </AppContainer>
  );
}

export default ReleaseScreen;
