import store from "../analytics/store";
import AppContainer from "../components/container";
import Header from "../components/header";
import Releases from "../components/releases";
import { track_home_screen } from "../analytics/events";
import "../css/app.css";

function Home(props) {
  store.getState().track(track_home_screen);
  return (
    <AppContainer>
      <Header />
      <a href="#releases" name="releases" className="anchor">
        .
      </a>
      <Releases />
    </AppContainer>
  );
}

export default Home;
