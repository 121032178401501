import React from "react";

class YouTubeVideo extends React.Component {
  constructor(props) {
    super(props);
    this.embed_url = "https://www.youtube.com/embed/" + props.video;
    this.title = props.artist + " - " + props.title;
  }
  render() {
    return (
      <iframe
        title={this.title}
        src={this.embed_url}
        frameBorder={"0"}
        allow={
          "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        }
        allowFullScreen
      ></iframe>
    );
  }
}

export default YouTubeVideo;
