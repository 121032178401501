import React from "react";
import HeaderNav from "../components/navigation";
import "../css/header.css";

class Header extends React.Component {
  render() {
    return (
      <header className="app-header">
        <HeaderNav />
      </header>
    );
  }
}

export default Header;
