import React from "react";
import store from "../analytics/store";
import { track_home_release_click } from "../analytics/events";
import "../css/app.css";
import "../css/releases.css";

let state = store.getState();

class ReleaseItem extends React.Component {
  render() {
    const image = require("../images/artwork/" + this.props.image).default;
    return (
      <div className="releaseItem">
        <div className="releaseImage">
          <a
            href={this.props.link}
            onClick={() => {
              state.track(track_home_release_click(this.props.id, "image"));
            }}
          >
            <img src={image} alt="" />
          </a>
        </div>
        <div className="releaseText">
          <small>
            <a
              href={this.props.link}
              onClick={() => {
                state.track(track_home_release_click(this.props.id, "text"));
              }}
            >
              <div className="releaseTitle">
                <b>{this.props.title}</b>
              </div>
              <div className="releaseArtist">
                <i>{this.props.artist}</i>
              </div>
            </a>
          </small>
        </div>
      </div>
    );
  }
}

export { ReleaseItem };
