import React from "react";
import { Switch, Route } from "react-router-dom";

import Home from "../screens/home";
import Release from "../screens/release";
import NotFound from "../screens/notFound";
import TrackCampaign from "../screens/trackCampaign";
import ArtistScreen from "../screens/artist";

const Main = () => {
  return (
    <Switch>
      {" "}
      {/* The Switch decides which component to show based on the current URL.*/}
      <Route exact path="/" component={Home} id={"HELLO"}></Route>
      <Route path="/artist/:artist" component={ArtistScreen}></Route>
      <Route path="/release/:id" component={Release}></Route>
      <Route path="/t/:artist/:id" component={TrackCampaign}></Route>
      <Route component={NotFound} />
    </Switch>
  );
};

export default Main;
