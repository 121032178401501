import React from "react";
import { releases } from "../content/latestReleases";
import { ReleaseItem } from "../components/releaseItem";
import ReleaseRow from "../components/releaseRow";

class Releases extends React.Component {
  render() {
    let releaseItems = [];
    let rows = [];
    let releaseOutput = [];

    for (const [index, release] of Object.entries(releases)) {
      releaseItems.push(
        <ReleaseItem
          image={release.image}
          link={`/release/${index}`}
          artist={release.artist}
          title={release.title}
          id={index}
          key={index}
        />
      );
    }

    releaseItems.forEach((item, i) => {
      if (i % 3 === 2 || i === releaseItems.length - 1) {
        rows.push(item);
        releaseOutput.push(<ReleaseRow key={i} rows={rows} />);
        rows = [];
      } else {
        rows.push(item);
      }
    });

    return releaseOutput[0];
  }
}

export default Releases;
