import React from "react";
import Footer from "../components/footer";

class AppContainer extends React.Component {
  render() {
    return (
      <div className="city">
        <div className="App">{this.props.children}</div>
        <Footer />
      </div>
    );
  }
}

export default AppContainer;
