// import app from "firebase/app";
// import "firebase/analytics";

// const firebaseConfig = {
//   apiKey: "AIzaSyDL1Ecc2nYrWSz64WaoA6sGxpLz92zRu08",
//   authDomain: "vxvmg-web.firebaseapp.com",
//   projectId: "vxvmg-web",
//   storageBucket: "vxvmg-web.appspot.com",
//   messagingSenderId: "79121429486",
//   appId: "1:79121429486:web:fea82b30f9b0dede2c14ff",
//   measurementId: "G-JDM67C3DLR",
// };

// app.initializeApp(firebaseConfig);

// export const track = (event) => {
//   // console.log(
//   //   `TRACKING ${event.eventName} with ${JSON.stringify(event.eventParameters)}`
//   // );
//   app.analytics().logEvent(event.eventName, event.eventParameters);
// };


// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent, setUserProperties } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDL1Ecc2nYrWSz64WaoA6sGxpLz92zRu08",
  authDomain: "vxvmg-web.firebaseapp.com",
  projectId: "vxvmg-web",
  storageBucket: "vxvmg-web.appspot.com",
  messagingSenderId: "79121429486",
  appId: "1:79121429486:web:fea82b30f9b0dede2c14ff",
  measurementId: "G-HMQJBXGDF2"
};

// Initialize Firebase

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const track = (event, userProps) => {
  console.log(
    `TRACKING ${event.eventName} with ${JSON.stringify(event.eventParameters)}`
  );
  if (userProps) {
    console.log(
      `SETTING USER PROPERTIES ${JSON.stringify(userProps)}`
    );
    setUserProperties(analytics, userProps);
  }
  logEvent(
    analytics,
    event.eventName,
    event.eventParameters || {}
  );
};

export default track;
