import React from "react";
import "../css/app.css";
import "../css/releases.css";

class ReleaseRow extends React.Component {
  render() {
    return <div className="release_row_3">{this.props.rows}</div>;
  }
}

export default ReleaseRow;
