export const campaigns = {
    kidneko: {
        hello_astronaut: {
            artist: "Kidneko",
            title: "Hello, Astronaut",
            spotify: "https://",
            youtube: "Rlcd4gLrZD0",
            apple: "https://",
            artwork: "lindom_kidneko-drinking_games.jpg",
            canvas: "palms-461940.jpg",
            blurBackground: false,
        }
    }
}