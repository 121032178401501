import React from "react";
import { Redirect } from "react-router-dom";
import { useParams } from "react-router-dom";
import CampaignContainer from "../components/containerCampaign";
import { campaigns } from "../content/trackCampaigns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpotify, faItunes } from "@fortawesome/free-brands-svg-icons";
import "../css/app.css";

function TrackCampaign() {
  const { artist, id } = useParams();
  let campaign;
  if (artist in campaigns) {
    if (id in campaigns[artist]) {
      campaign = campaigns[artist][id];
    } else {
      return <Redirect to={`/artist/${artist}`} />;
    }
  } else {
    return <Redirect to={"/"} />;
  }

  let canvasUrl = {};

  if (campaign.canvas) {
    canvasUrl.canvas = campaign.canvas;
  } else {
    canvasUrl.artwork = campaign.artwork;
  }

  return (
    <CampaignContainer
      canvasImage={canvasUrl}
      blurBackground={campaign.blurBackground}
    >
      <div className="campaignContainer">
        <div className="campaignInfo">
          <img
            src={require(`../images/artwork/${campaign.artwork}`).default}
            alt={`${campaign.artist} - ${campaign.title}`}
          />
          <h2>{campaign.title}</h2>
          <h4>{campaign.artist}</h4>
        </div>
        <div className="campaignButtons">
          <div className="serviceButton">
            <div className="serviceIcon">
              <FontAwesomeIcon
                icon={faSpotify}
                size="2x"
                className="fa-custom-hover"
              />
            </div>
            <div className="serviceName">Spotify</div>
          </div>
          <div className="serviceButton">
            <div className="serviceIcon">
              <FontAwesomeIcon
                icon={faItunes}
                size="2x"
                className="fa-custom-hover"
              />
            </div>
            <div className="serviceName">iTunes</div>
          </div>
        </div>
      </div>
    </CampaignContainer>
  );
}

export default TrackCampaign;
