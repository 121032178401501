import React from "react";
import { Redirect } from "react-router-dom";
import { useParams } from "react-router-dom";
import AppContainer from "../components/container";
import Header from "../components/header";
import { artists } from "../content/artists";
import "../css/app.css";

function ArtistScreen() {
  let _artist;
  const { artist } = useParams();
  if (artist in artists) {
    _artist = artists[artist];
  } else {
    return <Redirect to={"/"} />;
  }
  return (
    <AppContainer>
      <Header />
      {JSON.stringify(_artist)}
    </AppContainer>
  );
}

export default ArtistScreen;
