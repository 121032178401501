import React from "react";
import "../css/app.css";
import "../css/footer.css";

class Footer extends React.Component {
  render() {
    return (
      <div className="App-footer">
        <i className="font-thin font-small">&copy; VXMVG 2021 - 2023</i>
      </div>
    );
  }
}

export default Footer;
