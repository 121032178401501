import React from "react";
import "../css/app.css";
import "../css/header.css";
// import logo from "../images/vxvmg.png";

class HeaderNav extends React.Component {
  render() {
    return (
      <div className="header-container">
        <div className="header-logo">
          <a href="/">
            {/* <img src={logo} alt="logo" /> */}
            <h1>VXVMG</h1>
          </a>{" "}
        </div>
        <nav>
          <ul>
            {/* <li>
              <a href="/#releases" className="header-nav-link">
                releases
              </a>
            </li>
            <li>
              <a href="#publishing" className="header-nav-link">
                publishing
              </a>
            </li>
            <li>
              <a href="#artistservices" className="header-nav-link">
                artist services
              </a>
            </li>
            <li>
              <a href="/t/kidneko/hello_astronaut" className="header-nav-link">
                track campaign
              </a>
            </li> */}
          </ul>
        </nav>
      </div>
    );
  }
}

export default HeaderNav;
