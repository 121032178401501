export const releases = {
  kidneko_kbax_heal_my_heart: {
    artist: "Kidneko, K Bax",
    image: "kidneko_kbax-heal_my_heart.jpg",
    title: "Heal My Heart",
    date: "2020",
    link: "/release/heal_my_heart",
    youtube: "O4SgDqgnhvw",
    spotify:
      "https://open.spotify.com/track/2SikYmJ5ucCicgdQnh9mxK?si=3bba1467f5254a41",
    instagram: "kidneko",
    twitter: "kidnekobeats",
    musixmatch: "",
  },
  lindom_kidneko_drinking_games: {
    id: "lindom_kidneko_drinking_games",
    artist: "Kidneko, Lindo M",
    image: "lindom_kidneko-drinking_games.jpg",
    title: "Drinking Games",
    date: "10/2019",
    link: "/release/drinking_games",
    youtube: "W9bjxAbOyx4",
    spotify:
      "https://open.spotify.com/track/6pMXLdQNk0hmJLZfbEKVah?si=fc72639726e440c3",
    instagram: "acousticlindom",
    twitter: "kidnekobeats",
    musixmatch: "",
  },
  whatever_ohio_word_of_mouth: {
    artist: "Whatever, Ohio",
    image: "whatever_ohio-word_of_mouth.jpg",
    title: "Word Of Mouth",
    date: "10/2019",
    link: "https://vxvmg.com",
    youtube: "DVhFFrseZtI",
    spotify: "https://open.spotify.com/album/4BFDHLVlWHcmhdLm9wFwi5",
    twitter: "whatever_ohio",
    musixmatch: "https://www.musixmatch.com/lyrics/",
  },
  kidneko_walwin_ghosted: {
    artist: "Kidneko, WALWIN",
    image: "kidneko_walwin-ghosted.jpg",
    title: "Ghosted",
    date: "10/2019",
    link: "https://vxvmg.com",
    twitter: "kidnekobeats",
    instagram: "kidneko",
    musixmatch: "https://www.musixmatch.com/lyrics/WALWIN-Kidneko/Ghosted",
  },
  kidneko_walwin_get_along: {
    artist: "Kidneko, WALWIN",
    image: "kidneko_walwin-get_along.jpg",
    title: "Get Along",
    date: "10/2019",
    link: "https://vxvmg.com",
    twitter: "kidnekobeats",
    instagram: "kidneko",
    musixmatch: "https://www.musixmatch.com/lyrics/Kidneko-WALWIN-14/Get-Along",
  },
};

export const heroRelease = releases.lindom_kidneko_drinking_games;
